import React from "react";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { Link as ReactLink } from "react-router-dom";
import CopyRight from "../components/copyright";
import MainLayout from "../layouts/mainLayout";
import { useTranslation } from "react-i18next";

export default function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <MainLayout>
      <Typography component="h1" variant="h3">
        {t("not_found_page.title")}
      </Typography>
      <Typography component="h1" variant="h5" style={{ margin: "25px 0" }}>
        {t("not_found_page.look_for")}
      </Typography>
      <Grid container>
        <Grid item xs>
          <Link component={ReactLink} to="reset" variant="body2">
            {t("forgot_password")}
          </Link>
        </Grid>
        <Grid item>
          <Link component={ReactLink} to="register" variant="body2">
            {t("not_signed_up")}
          </Link>
        </Grid>
      </Grid>
      <Box mt={5}>
        <CopyRight />
      </Box>
    </MainLayout>
  );
}
