import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import LoginPage from "./pages/./loginPage";
import ResetPage from "./pages/resetPage";
import Reset2Page from "./pages/reset2Page";
import RegisterPage from "./pages/registerPage";
import VerifyPage from "./pages/verifyPage";
import NotFoundPage from "./pages/notFoundPage.jsx";
import CssBaseline from "@material-ui/core/CssBaseline";
import { telkeyLight } from "./themes";
import { ThemeProvider } from "@material-ui/core/styles";
import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import "./i18/i18n";

const basename = process.env.REACT_APP_BASENAME ?? "/";

ReactDOM.render(
  <ThemeProvider theme={telkeyLight}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <BrowserRouter basename={basename}>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/verify/:token" element={<VerifyPage />} />
        <Route path="/reset" element={<ResetPage />} />
        <Route path="/reset2/:token" element={<Reset2Page />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
