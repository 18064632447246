import React from "react";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@material-ui/core";
import ValidationErrorMessage from "./validationErrorMessage";

export default function ApproveTerms(props) {
  const { t } = useTranslation();
  const { isTouched, error, value, ...rest } = props;
  const isErrors = props?.error && !props.value && props?.isTouched;
  const color = { color: isErrors ? "red" : "black" };

  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Checkbox {...rest} type="checkbox" color="primary" style={color} />
        <span style={{ padding: "0 5px" }}>{t("approve_terms")}</span>
        <a
          style={{ color: "black" }}
          target="_blank"
          href="/terms-of-rules.pdf"
        >
          {t("terms_of_rules")}
        </a>
      </div>
      <ValidationErrorMessage error={props.error} isTouched={props.isTouched} />
    </div>
  );
}
