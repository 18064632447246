import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { Formik, Form, Field } from "formik";
import { Button, LinearProgress } from "@material-ui/core";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import formStyles from "../formStyles";
import Alert from "@material-ui/lab/Alert";
import {
  Link as ReactLink,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CopyRight from "../components/copyright";
import MainLayout from "../layouts/mainLayout";
import { useTranslation } from "react-i18next";

export default function ResetPage() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const classes = formStyles();
  const [httpResponse, setHttpResponse] = useState("");

  const resetSettings = {
    initialValues: {
      email: searchParams.get("email") || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("validation.invalid_email"))
        .required(t("validation.required")),
    }),
    validateOnBlur: false,
    onSubmit: (values, { setSubmitting }) => {
      fetch("/account/resetpw", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then((response) => response.json())
        .then((data) => {
          setHttpResponse(data);
        })
        .catch((error) => {
          setHttpResponse({ error_code: "500" });
          console.error("Error:", error);
        })
        .finally(() => setSubmitting(false));
    },
  };

  return (
    <MainLayout>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {t("reset_password")}
      </Typography>
      <Formik {...resetSettings}>
        {({ submitForm, isSubmitting }) => (
          <Form className={classes.form}>
            {(httpResponse.error_code === "500" && (
              <Alert variant="outlined" severity="error">
                {t("errors.something_went_wrong")}
              </Alert>
            )) ||
              (httpResponse.status === "success" && (
                <Alert variant="outlined" severity="success">
                  {t("success.reset_sent")}
                </Alert>
              )) ||
              (httpResponse && (
                //Default
                <Alert variant="outlined" severity="error">
                  {t("errors.something_went_wrong_contact_admin")}
                </Alert>
              ))}
            <Field
              component={TextField}
              name="email"
              type="email"
              label={t("email")}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              autoComplete="email"
              autoFocus
            />
            <br />
            {isSubmitting && <LinearProgress />}
            <br />
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={submitForm}
              type="submit"
              fullWidth
              className={classes.submit}
            >
              {t("send_reset")}
            </Button>
          </Form>
        )}
      </Formik>
      <Grid container>
        <Grid item xs>
          <Link component={ReactLink} to="/" variant="body2">
            {t("all_ready_registered")}
          </Link>
        </Grid>
        <Grid item>
          <Link component={ReactLink} to="/register" variant="body2">
            {t("not_signed_up")}
          </Link>
        </Grid>
      </Grid>
      <Box mt={5}>
        <CopyRight />
      </Box>
    </MainLayout>
  );
}
