import { localeLanguageKey, supportedLanguages } from "../constans/constans";

export const getCurrentLocale = () => {
  const browserLang = navigator?.language || navigator?.userLanguage;
  const i18nLanguage = localStorage.getItem(localeLanguageKey);
  if (!!i18nLanguage) {
    return i18nLanguage;
  }
  if (browserLang === "en-US" || browserLang === supportedLanguages.EN) {
    return "en";
  }
  return "sv";
};
