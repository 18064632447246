import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import { useParams } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { Formik, Form, Field } from "formik";
import {
  Button,
  IconButton,
  InputAdornment,
  LinearProgress,
} from "@material-ui/core";
import { TextField } from "formik-material-ui";
import formStyles from "../formStyles";
import Alert from "@material-ui/lab/Alert";
import { Link as ReactLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import PasswordStrengthBar from "react-password-strength-bar";
import zxcvbnInputs from "../constants";
import ApproveTerms from "../components/approveTerms";
import * as Yup from "yup";
import CopyRight from "../components/copyright";
import MainLayout from "../layouts/mainLayout";
import { useTranslation } from "react-i18next";
import { Visibility, VisibilityOff } from "@material-ui/icons";

export default function Reset2Page(props) {
  const { token } = useParams();
  const { t } = useTranslation();

  const classes = formStyles();
  const [httpResponse, setHttpResponse] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
    setIsShowPassword(!isShowPassword);
  };
  const handleMouseLeavePassword = (event) => {
    setIsShowPassword(false);
  };

  const navigate = useNavigate();

  const reset2Settings = {
    initialValues: {
      password: "",
      conditions: false,
    },
    validationSchema: Yup.object({
      password: Yup.string().required(t("validation.required")),
      conditions: Yup.boolean().oneOf([true], t("validation.required")),
    }),
    validateOnBlur: false,
    onSubmit: (values, { setSubmitting }) => {
      const { conditions, ...restValues } = values;

      fetch("/account/setpw/" + token, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(restValues),
      })
        .then((response) => {
          if (response.redirected) {
            navigate(response.url);
            return JSON.stringify({ status: "success" });
          }
          return response.json();
        })
        .then((data) => {
          setHttpResponse(data);
        })
        .catch((error) => {
          setHttpResponse({ error_code: "500" });
          console.error("Error:", error);
        })
        .finally(() => setSubmitting(false));
    },
  };

  return (
    <MainLayout>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        {t("new_password")}
      </Typography>
      <Formik {...reset2Settings}>
        {({
          handleBlur,
          handleChange,
          isSubmitting,
          values,
          touched,
          errors,
        }) => (
          <Form className={classes.form}>
            {(httpResponse.error_code === "40007" && (
              <Alert variant="outlined" severity="error">
                {t("errors.reset_password_expired")}{" "}
                <Link component={ReactLink} to="/reset" variant="body3">
                  {t("reset_password").toLowerCase()}
                </Link>
              </Alert>
            )) ||
              (httpResponse.error_code === "40101" && (
                <Alert variant="outlined" severity="error">
                  {t("errors.reset_password_invalid")}
                </Alert>
              )) ||
              (httpResponse.error_code === "500" && (
                <Alert variant="outlined" severity="error">
                  {t("errors.something_went_wrong")}
                </Alert>
              )) ||
              (httpResponse.error_code === "40005" && (
                <Alert variant="outlined" severity="error">
                  {t("errors.password_is_not_strong_enough")}
                </Alert>
              )) ||
              (httpResponse.status === "success" && (
                <Alert variant="outlined" severity="success">
                  {t("success.reset_success")}
                </Alert>
              )) ||
              (httpResponse && (
                //Default
                <Alert variant="outlined" severity="error">
                  {t("errors.something_went_wrong_contact_admin")}
                </Alert>
              ))}
            <Field
              component={TextField}
              type={isShowPassword ? "text" : "password"}
              label={t("password")}
              name="password"
              variant="outlined"
              margin="normal"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onMouseDown={handleMouseDownPassword}
                      onMouseLeave={handleMouseLeavePassword}
                    >
                      {isShowPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
              id="password"
              autoComplete="new-password"
            />
            <PasswordStrengthBar
              password={values.password}
              userInputs={zxcvbnInputs}
              scoreWords={[
                t("password_is.to_weak"),
                t("password_is.very_weak"),
                t("password_is.acceptable"),
                t("password_is.good"),
                t("password_is.strong"),
              ]}
              shortScoreWord={t("password_is.to_weak")}
            />
            <ApproveTerms
              id="conditions"
              name="conditions"
              onChange={handleChange}
              error={errors?.conditions}
              value={values.conditions}
              isTouched={touched.conditions}
              autoComplete="conditions"
              onBlur={handleBlur}
            />
            {isSubmitting && <LinearProgress />}
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              type="submit"
              fullWidth
              className={classes.submit}
            >
              {t("save_password")}
            </Button>
          </Form>
        )}
      </Formik>
      <Grid container>
        <Grid item xs>
          <Link component={ReactLink} to="/" variant="body2">
            {t("all_ready_registered")}
          </Link>
        </Grid>
        <Grid item>
          <Link component={ReactLink} to="/register" variant="body2">
            {t("not_signed_up")}
          </Link>
        </Grid>
      </Grid>
      <Box mt={5}>
        <CopyRight />
      </Box>
    </MainLayout>
  );
}
