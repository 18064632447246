import { MenuItem, Select } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { languageOptions } from "../constans/constans";

export default function LanguageDropdown() {
  const { i18n } = useTranslation();

  const handleChangeLanguage = (language) => {
    i18n.changeLanguage(language.target.value);
  };

  return (
    <Select
      value={i18n?.language}
      variant="outlined"
      onChange={handleChangeLanguage}
    >
      {languageOptions.map((language) => (
        <MenuItem key={language.value} value={language.value}>
          {language.label}
        </MenuItem>
      ))}
    </Select>
  );
}
