import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./locales/en/en.json";
import sv from "./locales/sv/sv.json";
import { getCurrentLocale } from "../helpers/getCurrentLocale";

const currentLocale = getCurrentLocale();

const resources = {
  en: {
    translation: en,
  },
  sv: {
    translation: sv,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: currentLocale,
    resources,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
export default i18n;
