import React from "react";
import { useTranslation } from "react-i18next";

export default function ValidationErrorMessage(props) {
  const { t } = useTranslation();

  if (!props?.error || !props?.isTouched) {
    return null;
  }

  return (
    <p style={{ color: "red", margin: "3px 14px 0px", fontSize: "12px" }}>
      {t(props.error)}
    </p>
  );
}
