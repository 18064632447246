import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Link from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import PersonAdd from "@material-ui/icons/PersonAdd";
import Typography from "@material-ui/core/Typography";
import { Formik, Form, Field } from "formik";
import {
  Button,
  IconButton,
  InputAdornment,
  LinearProgress,
} from "@material-ui/core";
import { TextField } from "formik-material-ui";
import * as Yup from "yup";
import formStyles from "../formStyles";
import Alert from "@material-ui/lab/Alert";
import { Link as ReactLink } from "react-router-dom";
import PasswordStrengthBar from "react-password-strength-bar";
import zxcvbnInputs from "../constants";
import ApproveTerms from "../components/approveTerms";
import CopyRight from "../components/copyright";
import MainLayout from "../layouts/mainLayout";
import { useTranslation } from "react-i18next";
import { passwordScoreWords } from "../constans/constans";
import { Visibility, VisibilityOff } from "@material-ui/icons";

export default function RegisterPage() {
  const { t } = useTranslation();

  const classes = formStyles();
  const [httpResponse, setHttpResponse] = useState("");
  const [isShowPassword, setIsShowPassword] = useState(false);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
    setIsShowPassword(!isShowPassword);
  };
  const handleMouseLeavePassword = (event) => {
    event.preventDefault();
    setIsShowPassword(false);
  };

  const initialValues = {
    name: "",
    surname: "",
    email: "",
    password: "",
    conditions: false,
  };

  const registerSettings = {
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required(t("validation.required")),
      surname: Yup.string().required(t("validation.required")),
      email: Yup.string()
        .email(t("validation.invalid_email"))
        .required(t("validation.required")),
      password: Yup.string().required(t("validation.required")),
      conditions: Yup.boolean().oneOf([true], t("validation.required")),
    }),
    validateOnBlur: false,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      const { conditions, ...restValues } = values;
      fetch("/account/register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(restValues),
      })
        .then((response) => response.json())
        .then((data) => {
          setHttpResponse(data);
          resetForm();
        })
        .catch((error) => {
          setHttpResponse({ error_code: "500" });
          console.error("Error:", error);
        })
        .finally(() => setSubmitting(false));
    },
  };

  return (
    <MainLayout>
      <Avatar className={classes.avatar}>
        <PersonAdd />
      </Avatar>
      <Typography component="h1" variant="h5">
        {t("register")}
      </Typography>
      <Formik {...registerSettings}>
        {({
          touched,
          handleBlur,
          isSubmitting,
          values,
          handleChange,
          errors,
        }) => (
          <Form className={classes.form}>
            {(httpResponse.error_code === "40900" && (
              <Alert variant="outlined" severity="error">
                {t("errors.email_already_registered")}
              </Alert>
            )) ||
              (httpResponse.error_code === "500" && (
                <Alert variant="outlined" severity="error">
                  {t("errors.something_went_wrong")}
                </Alert>
              )) ||
              (httpResponse.error_code === "40005" && (
                <Alert variant="outlined" severity="error">
                  {t("errors.password_is_not_strong_enough")}
                </Alert>
              )) ||
              (httpResponse.id && (
                <Alert variant="outlined" severity="success">
                  {t("success.register_success")}
                </Alert>
              )) ||
              (httpResponse && (
                <Alert variant="outlined" severity="error">
                  {t("errors.something_went_wrong_with_problem", {
                    problem: httpResponse.error_message,
                  })}
                </Alert>
              ))}
            <Field
              component={TextField}
              name="name"
              type="name"
              label={t("name")}
              variant="outlined"
              margin="normal"
              fullWidth
              id="name"
              autoComplete="given-name"
              autoFocus
            />
            <Field
              component={TextField}
              name="surname"
              type="surname"
              label={t("surname")}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="surname"
              autoComplete="family-name"
            />
            <Field
              component={TextField}
              name="email"
              type="email"
              label={t("email")}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              autoComplete="email"
            />
            <Field
              component={TextField}
              type={isShowPassword ? "text" : "password"}
              label={t`password`}
              name="password"
              variant="outlined"
              margin="normal"
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onMouseDown={handleMouseDownPassword}
                      onMouseLeave={handleMouseLeavePassword}
                    >
                      {isShowPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              fullWidth
              id="password"
              autoComplete="new-password"
            />
            <PasswordStrengthBar
              password={values.password}
              userInputs={zxcvbnInputs}
              scoreWords={passwordScoreWords.map((word) => t(word))}
              shortScoreWord={t("password_is.to_weak")}
            />
            {touched?.conditions}
            <ApproveTerms
              id="conditions"
              name="conditions"
              onChange={handleChange}
              error={errors?.conditions}
              value={values.conditions}
              checked={values.conditions}
              isTouched={touched.conditions}
              autoComplete="conditions"
              onBlur={handleBlur}
            />
            {isSubmitting && <LinearProgress />}
            <Button
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              type="submit"
              fullWidth
              className={classes.submit}
            >
              {t(`register`)}
            </Button>
          </Form>
        )}
      </Formik>
      <br />
      <Grid container>
        <Grid item xs>
          <Link component={ReactLink} to="/reset" variant="body2">
            {t("forgot_password")}
          </Link>
        </Grid>
        <Grid item>
          <Link component={ReactLink} to="/" variant="body2">
            {t("all_ready_registered")}
          </Link>
        </Grid>
      </Grid>
      <Box mt={5}>
        <CopyRight />
      </Box>
    </MainLayout>
  );
}
