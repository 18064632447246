export const supportedLanguages = {
  SV: "sv",
  EN: "en",
};

export const languageOptions = [
  { label: "EN", id: 1, value: "en" },
  { label: "SV", id: 2, value: "sv" },
];

export const localeLanguageKey = "i18nextLng";

export const passwordScoreWords = [
  "password_is.to_weak",
  "password_is.very_weak",
  "password_is.acceptable",
  "password_is.good",
  "password_is.strong",
];
