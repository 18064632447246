import React, { useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import formStyles from "../formStyles";
import { getCurrentLocale } from "../helpers/getCurrentLocale";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "../components/languageDropdown";

export default function MainLayout(props) {
  const { i18n } = useTranslation();

  useEffect(() => {
    const currentLocale = getCurrentLocale();
    i18n.changeLanguage(currentLocale);
  }, []);

  const classes = formStyles();

  return (
    <Grid container component="main" className={classes.root}>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        lg={4}
        xl={3}
        component={Paper}
        elevation={6}
        square
      >
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginRight: "20px",
            marginTop: "20px",
          }}
        >
          <LanguageDropdown />
        </div>
        <div className={classes.paper}>{props.children}</div>
      </Grid>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        lg={8}
        xl={9}
        className={classes.image}
      />
    </Grid>
  );
}
